a:link {
  text-decoration: none;
}

/* MUI primary color 3f51b5 */

a, a:visited {
  color: 'inherit'
}

.App {
  text-align: center;
}

.AppBar-logo {
  height: 40px;
  pointer-events: none;
}

.AppBar-type {
  color: white;
  text-decoration: none;
}

.block {
  display: block;
}

@media (prefers-reduced-motion: no-preference) {
  .AppBar-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-logo-row {
  display: flex;
  flex-direction: row;
}

.App-link {
  color: #61dafb;
}

.background {
  background-color: #e9f1ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.section {
  background-color: #e9f1ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}

.section-full-height {
  background-color: #e9f1ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: '100%';
  padding-bottom: 20px;
}

.whiteCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home-footer-section {
  background-color: #3f51b5;
  color: white;
  width: 100%;
}

.home-footer-text {
  padding: 12px 0;
  cursor: pointer;
}

.title-container {
  margin-top: 5px;
}

.outer-video-container {
  text-align: right;
  margin-bottom: 20px;
  width: 60%;
}

.outer-project-container {
  text-align: right;
  margin-bottom: 20px;
  width: 100%;
}

.video-info-container {
  display: flex;
  justify-content: space-between;
  align-content: space-around;
  padding: 8px 15px;
  width: 100%;
  border-radius: 7px;
  border: 1px solid rgb(34, 25, 108);
  cursor: pointer;
}

.video-player-container {
  padding: 0rem;
  border: 5px solid rgb(184, 192, 228);
}

.video-player {
  position: relative;
  margin: 0rem;
  z-index: 1;
}

.comment-section {
  margin: 20px 0px;
  width: 100%;
}

.comment-box {
  background-color: green;
  color: white;
}

.comment-text {
  padding: 16px 14px;
}

.icon-margins {
  margin-right: 8px;
  margin-top: 5px;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.side-image-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.backgroundImage {
  width: 100%;
}

.sideImage {
  width: 50%;
}

.sideImageWide {
  width: 50%;
  display: block
}

.sideImageNarrow {
  width: 50%;
  display: none
}

.canvas:hover {
  cursor: pointer;
}

.text-overlay {
  position: absolute;
  color: white;
}

.full-length-copy-container {
  margin: 1vw 5vw;
}

.project-list-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* border: 1px solid rgb(34, 25, 108); */
  border-radius: 7px;
  width: 500px;
  cursor: pointer;
  background-color: white;
}

.project-details-description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 60%;
  margin: 20px 0px;
}

.project-listing-text {
  text-align: left;
  margin: 15px 0px;
}

.add-video-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-video-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 8px 0px;
  border: 1px solid rgb(34, 25, 108);
  border-radius: 7px;
  padding: 10px;
}

.add-video-row:hover {
  cursor: pointer;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 900px) {
  .video-info-container {
    display: block;
    text-align: center;
    padding: 8px 15px;
    width: 100%;
    border-radius: 7px;
    border: 1px solid rgb(34, 25, 108);
    cursor: pointer;
  }
  .sideImage {
    width: 100%;
  }
  .side-image-box {
    display: flex;
    flex-direction: column;
  }
  .sideImageWide {
    width: 100%;
    display: none;
  }
  .sideImageNarrow {
    width: 100%;
    display: block
  }
}

@media only screen and (max-width: 700px) {
  .project-list-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* border: 1px solid rgb(34, 25, 108); */
    border-radius: 7px;
    width: 350px;
    cursor: pointer;
    background-color: white;
  }

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
